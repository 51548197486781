<template>
	<v-app-bar
		id="app-bar"
		absolute
		app
		flat
		color="transparent"
		height="130"
	>
		<v-btn
			class="ml-1 app_bar_button"
			small
			@click="setDrawer(!drawer)"
		>
			<img :src="burgerMenuPrimary" class="app-bar-settings-icon">
		</v-btn>
		<v-col cols="10" xl="10" lg="8" md="6" sm="6" class="ma-0 pa-0 app_bar_description">
			<span class="app_bar_main_path" style="font-size: 25px"> {{ $t(pathname) }} </span>
		</v-col>
		<v-spacer />
		<v-menu
			rounded="lg"
			bottom
			left
			offset-y
			origin="top right"
			transition="scale-transition"
      style="z-index: 10"
		>
			<template v-slot:activator="{ attrs, on }">
				<v-btn v-bind="attrs" v-on="on" class="mr-2 app_bar_settings_button" style="border-radius: 12px">
					<img :src="getImageForRole" class="app-bar-settings-icon">
					<span class="pl-2 preferences-title">{{userFullName}}</span>
				</v-btn>
			</template>
			<v-list style="border-radius: 0px !important" :tile="false" nav dense>
        <template>
          <v-list-item-group>
            <v-list-item style="background-color: #E8EE8E8 !important;" v-for="(item, i) in userMenu" :key="i" @click="redirect(item)">
              <v-list-item-icon>
                <img :src="item.icon" class="px-0">
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="d-flex align-left pl-0 pt-1" v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </template>
      </v-list>
		</v-menu>
		<ProfileDialog v-if="settingsDialog" :dialog="settingsDialog" @false="settingsDialog = false"></ProfileDialog>
	</v-app-bar>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { burgerMenu, settingsBlack, profileBlack, burgerMenuPrimary, profileSettingsBlack, signOutBlack, doctorBlack, nurseBlack } from '@/assets/index';
import ProfileDialog from '../dialogs/ProfileDialog.vue';

export default {
	name: 'DashboardCoreAppBar',
	components: {
		ProfileDialog,
	},
	data: () => ({
		burgerMenu_icon: burgerMenu,
		profile_icon: profileBlack,
		settings_icon: settingsBlack,
		chosenUnit: 'metric',
		chosenGCh: 'mmol',
		settingsDialog: false,
		profileSettingsBlack: profileSettingsBlack,
		burgerMenuPrimary: burgerMenuPrimary,
		doctorBlack: doctorBlack,
		nurseBlack: nurseBlack,
	}),
	computed: {
		...mapGetters({ roleName: 'authentication/getRole', userFullName: 'authentication/getFullName' }),
		...mapState(['drawer']),
		pathname () {
			return this.$route.name;
		},
		userMenu () {
			return [
				// {
				// 	text: this.$t('about'),
				// 	action: 'about',
				// 	icon: vitalCheckupsNoTitle,
				// },
				{
					text: this.$t('settings'),
					action: 'settings',
					icon: profileSettingsBlack,
				},
				{
					text: this.$t('signout'),
					action: 'signout',
					icon: signOutBlack,
				},
			];
		},
		getImageForRole () {
			switch (this.roleName) {
			case 'DOCTOR':
				return this.doctorBlack;
			case 'NURSE':
				return this.nurseBlack;
			case 'HOSPITAL_ADMIN':
				return this.profileSettingsBlack;
			case 'SYSTEM_ADMIN':
				return this.profileSettingsBlack;
			default: return this.profileSettingsBlack;
			}
		},
	},
	methods: {
		...mapMutations({
			setDrawer: 'SET_DRAWER',
		}),
		selected (item) {
			localStorage.setItem('unit', item);
			switch (item) {
			case 'metric':
				this.chosenUnit = 'metric';
				break;
			case 'imperial':
				this.chosenUnit = 'imperial';
				break;
			}
		},
		selectedGCh (item) {
			localStorage.setItem('glucose/cholesterol', item);
			switch (item) {
			case 'mmol':
				this.chosenGCh = 'mmol';
				break;
			case 'mg':
				this.chosenGCh = 'mg';
				break;
			}
		},
		signout () {
			this.$router.push({ name: 'Login' });
		},
		redirect (item) {
			switch (item.action) {
			case 'about':
				if (this.$i18n.locale === 'en') {
					window.open('https://www.vitalcheckups.com', '_blank');
				} else {
					window.open(`https://www.vitalcheckups.com/${this.$i18n.locale}`, '_blank');
				}
				break;
			case 'settings':
				this.settingsDialog = true;
				break;
			case 'signout':
				this.signout();
				break;
			default:
				break;
			}
		},
	},
};
</script>

<style>
</style>
